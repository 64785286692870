(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
function MatchHeight() {
  $('.matchh').matchHeight();

}
function SelectDropdown(Obj) {

	if ($(Obj).val().length > 0) {
		$(Obj).parents('.wizard-form').find('.filter_button, .filter_button-cmp').prop("disabled", false);
	}
	else {
	    $(Obj).parents('.wizard-form').find('.filter_button, .filter_button-cmp').prop("disabled", true);
	}

	if($(Obj).val().length >= 1){
		$(Obj).parents("form").find(".selected-item").addClass('show');
		$(Obj).parents("form").find('.select2-selection--multiple').addClass('pading');
	}else{
		$(Obj).parents("form").find(".selected-item").removeClass('show');
		$(Obj).parents("form").find('.select2-selection--multiple.pading').removeClass('pading');
	}
	$(Obj).parents("form").find(".selected-item").text($(Obj).val().length);
	var rnd_width = $(Obj).parents("form").find('.select2-selection__rendered'),
	ulwrap_width = $(Obj).parents("form").find(".select2-selection--multiple").width();
	if($(rnd_width).outerWidth() >= ulwrap_width){
		$(Obj).parents("form").find('.select2-selection__rendered').addClass('truncate');
	}
}

function UnselectDrop(Obj) {
	if ($(Obj).val().length > 0) {
        $(Obj).parents('.wizard-form').find('.filter_button, .filter_button-cmp').prop("disabled", false);
    }
    else {
        $(Obj).parents('.wizard-form').find('.filter_button,.filter_button-cmp').prop("disabled", true);
    }
	if($(Obj).val().length < 1){
		$(Obj).parents("form").find(".selected-item.show").removeClass('show');
		$(Obj).parents("form").find('.select2-selection--multiple.pading').removeClass('pading');
	}else{
		$(Obj).parents("form").find(".selected-item").addClass('show');
		$(Obj).parents("form").find('.select2-selection--multiple').addClass('pading');
	}
    $(Obj).parents("form").find(".selected-item").text($(Obj).val().length);
    	var rnd_width = $(Obj).parents("form").find('.select2-selection__rendered'),
		ulwrap_width = $(Obj).parents("form").find(".select2-selection--multiple").width();
		if($(rnd_width).outerWidth() < ulwrap_width){
			$(Obj).parents("form").find('.select2-selection__rendered').removeClass('truncate');
		}
}

$(window).on('scroll','',function() {
	var scrollAnchor = $(".btn-scroll");
	if($(window).scrollTop() === 0) {
		scrollAnchor.removeClass('up');
		scrollAnchor.addClass('down')
	} else {
		scrollAnchor.removeClass('down');
		scrollAnchor.addClass('up');
	}
  });

$(document).ready(function(eh) {
	MatchHeight();

	jQuery.fn.outerHTML = function(s) {
	    return s
	        ? this.before(s).remove()
	        : jQuery("<p>").append(this.eq(0).clone()).html();
	};

	const rteTable = $('.rte table').not('.no_wrap, .two-no_wrap');
    rteTable.wrap('<div class="table-style"/>');

	var scrollAnchor = $('<div class="btn-scroll down"></div>');
	scrollAnchor.on('click', '', function(){
		if($(window).scrollTop() === 0) {
			$("html, body").animate({ scrollTop: $(document).height() }, 1000);
			scrollAnchor.removeClass('down');
			scrollAnchor.addClass('up')
		} else {
			window.scrollTo(0, 0);
		}
	});
        $("body").append(scrollAnchor);

	// Footer
	var footer_social 	= $("footer .footer-col ul.social-share").outerHTML(),
		footer_socialwrap= $(footer_social).wrap("<div class='footer-mobi'></div>"),
		footer_logo		= $("footer .footer-col .logo-footer").outerHTML(),
		footer_copyright= $("footer .copyright").outerHTML();
	$(footer_socialwrap).insertAfter('footer .footer-row');
	$(footer_socialwrap).wrap("<div class='footer-mobi'></div>");
	$(footer_logo).insertAfter('footer .footer-mobi .social-share');
	$(".footer-mobi .logo-footer").wrap("<div class='logo-wrap'></div>");
	$(footer_copyright).insertAfter('footer .footer-mobi .logo-wrap');

	$('.select2').select2({
	});

	/* multiselect */
	$(".js-select2").select2({
		closeOnSelect : false,
		placeholder : "Select Here",
		allowHtml: true,
		allowClear: true,
		tags: true
	});

	// Normal Select design
	$(".custom_select").each(function() {
		var classes = $(this).attr("class");
		var template = '<div class="' + classes + '">';
		template += '<span class="custom-select-trigger">' + $(this).attr("placeholder") + "</span>";
		template += '<div class="custom_options">';
		$(this).find("option").each(function() {
			template += '<span class="custom_option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + "</span>";
		});
		template += "</div></div>";
		$(this).wrap('<div class="custom-select-wrapper"></div>');
		$(this).hide();
		$(this).after(template);
	});
	$(".custom-select-trigger").on("click", function(event) {
		$("html").one("click", function() {
			$(".custom_select").removeClass("opened");
		});

		$(this).parents(".custom_select").toggleClass("opened");
		event.stopPropagation();
	});

	// Custom Option
	$(".custom_option").on("click", function() {
		$(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
		$(this).parents(".custom_options").find(".custom_option").removeClass("selection");
		$(this).addClass("selection");
		$(this).parents(".custom_select").removeClass("opened");
		$(this).parents(".custom_select").find(".custom-select-trigger").text($(this).text());
		$(this).parents(".custom_select").find('.custom-select-trigger').addClass("selection");
	});

	var Search_bar 	= $("header .search-box").outerHTML(),
		header_cont	= $("header .head-contact").outerHTML();
	$('header .mainmenu .hs-menu-wrapper > ul').append(Search_bar);
	$('header .mainmenu .search-box').wrap("<li class='searchwrap-mobi'></li>");
	$('header .mainmenu .hs-menu-wrapper > ul').append(header_cont);
	$('header .mainmenu .head-contact').wrap("<li class='headcont-mobi'></li>");

	// Menu
	$(".mainmenu .hs-menu-wrapper").before('<a class="mobile-triggerm"><em></em></a>');
	$(".mainmenu .hs-menu-wrapper").prepend('<a class="mobile-triggerm open close"><em></em></a>');
	$(".mainmenu .has-sublist > a").after('<span class="child-triggerm"><span></span></span>');
	$("a.mobile-triggerm").click(function(e){
		e.preventDefault();
		$(this).next(".mainmenu .hs-menu-wrapper").toggleClass('show');
		$("body").addClass("mobile-open");
		return false
	});

	$("a.mobile-triggerm.close").click(function(evs){
		evs.preventDefault();
		$(this).parent(".hs-menu-wrapper").toggleClass('show');
		$("body").removeClass("mobile-open");
		$("span.child-triggerm").removeClass("child-open");
		$(".childmenu-wrapper").slideUp(300);
		$(".login > a + ul").slideUp(300);
		$('.search-box').removeClass('active-search');
		return false
	});

	// Search Wizard
    $('select').on("select2:select", function (es) {
    	es.preventDefault();
    	SelectDropdown(this);
    });
    $('select').on("select2:unselect", function (eu) {
    	eu.preventDefault();
    	UnselectDrop(this);
    });
	$(".clearFilter").on("click", function (ec) {
    	ec.preventDefault();
	    $(this).parents(".course-filter").find("select").each(function () {
	        $(this).val(null).trigger("change");
	        $(this).parents("form").find(".selected-item").text($(this).val().length);
	        $(this).parents("form").find(".selected-item.show").removeClass('show');
	        $(this).parents("form").find('.select2-selection__rendered.truncate').removeClass('truncate');
	        $(this).parents("form").find('.select2-selection--multiple.pading').removeClass('pading');
	        $(this).parents('.wizard-form').find('.filter_button, .filter_button-cmp').prop("disabled", true);
	    });
	});

	$('header .login > a').on('click',function(ev){
		ev.preventDefault();
		$(this).toggleClass('active');
		$(this).next().slideToggle();
	});

	$("html, body").click(function(ev) {
		if ($(ev.target).hasClass('active')) {
			return false;
		}
		$('header .login > a').removeClass('active').next().slideUp();
	});

	$("a.search-click").on("click",function(){
		$(this).parent('.search-box').toggleClass('active-search');
	});
	$(".close-search").on("click",function(){
		$(this).parent('.search-box').removeClass('active-search');
	});

	// Fancybox
	$(".popupclose").on("click",function(){
		$(this).parents('.fancybox__container').find(".fancybox__backdrop").trigger('click');
	});

	// Owl slider
	$(".news-slider.owl-carousel").owlCarousel({
		nav: true,
		loop:false,
		margin:24,
		smartSpeed:450,
		items:4,
		responsive:{
			576:{
				items: 2,
				slideBy:2
			},
			1200:{
				items: 3,
				slideBy:3
			}
		}
	});
	$(".business-news .business-slider.owl-carousel").owlCarousel({
		loop:false,
		margin:20,
		nav:true,
		responsive:{
			0:{
				items:1
			},
			576:{
				items: 2,
				slideBy:2
			},
			1200:{
				items: 3
			}
		}
	});
	$(".ca-news .ca-slider.owl-carousel").owlCarousel({
		loop:false,
		margin:20,
		nav:true,
		responsive:{
			0:{
				items:1
			},
			576:{
				items: 2,
				slideBy:2
			},
			1200:{
				items: 3
			}
		}
	});

	$('.cta-carousel').slick({
		dots: false,
		infinite: false,
		speed: 650,
		slidesToShow: 1.716,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.campaign-slider').owlCarousel({
		nav:true,
		loop:false,
		margin:0,
		smartSpeed:450,
		items:1
	});

	$('.course-slider').slick({
		dots: false,
		infinite: false,
		speed: 650,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});

	$('.tab_list.owl-carousel').owlCarousel({
		nav: true,
		loop: false,
		margin: 21,
		autoWidth: true,
		items: 4,
		touchDrag: false,
		mouseDrag: false,
		smartSpeed:450,
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 4
			}
		}
	});

	/* PARTNER SLIDER */
	$('.owl-carousel.partner-slider').owlCarousel({
		loop:false,
		margin:10,
		nav:true,
		responsive:{
			0:{
				items:1
			},
			600:{
				items:2
			},
			1000:{
				items:3
			}
		}
	})

	// Tab js
	var tab_List = $(".tabs-section .tab_list").outerHTML();
	var tab_Listm = $(tab_List).addClass("tab-mobi");
	$(tab_Listm).insertAfter($(".tabs-section .select_tab"));
	$('.tab_list.tab-mobi').removeClass('owl-carousel');

	$('.tab_list .owl-item:first .tab_link a,.tab_list .slick-slide:first .tab_link a').addClass('active');
	$('.tab_content').hide();

	$('.tab_list .tab_link a').click(function(event) {
		event.preventDefault();
		$(this).parents(".owl-item, .slick-slide").siblings().find('a.active').removeClass("active");
		$(this).addClass("active");
		$(this).parents(".tab-wrap").next(".tab_content-group").find(".tab_content").hide();
		var indexer = $(this).parents('.owl-item, .slick-slide').index();
		$(this).parents(".tab-wrap").next(".tab_content-group").find('.tab_content:eq(' + indexer + ')').fadeIn();
	});
	$(".select_tab input").on('click',function(){
		$(this).toggleClass('active');
		$(this).parent('.select_tab').next('.tab_list').slideToggle();
	});
	$('.tab_list.tab-mobi .tab_link a').on('click',function(){
		var text = $(this).text();
		$(this).removeClass('active');
		$(this).parents('.tab_list').slideUp().addClass('smooth-scrl');
		$(this).parents('.tab-wrap').find('.select_tab input').val(text);
		$(this).parents('.tab-wrap').find('.select_tab input.active').removeClass("active");
	});
	$(".tabs-section .tab-wrap .tab_list .owl-item:first-child .tab_link a,.tabs-section .tab-wrap .tab_list .slick-slide:first-child .tab_link a").trigger('click');

	if (window.matchMedia('(max-width: 767px)').matches) {
		$('.footer-row').masonry({
		  itemSelector: '.footer-col',
		  horizontalOrder: true
		});
	}

});

$(window).on('load',function(){
	$('.tab_list.smooth-scrl .tab_link a').on('click',function(){
		$('html, body').animate({
			scrollTop: $($(this).parents('.tabs-section')).offset().top
		}, 1000);
	});
});
window.addEventListener('resize', function() {
	if (window.innerWidth > 768) { // Adjust the width value as needed
		if (document.querySelectorAll('.cta-carousel').length > 0) {
			const windowHeight = window.innerHeight;
			const documentHeight = document.documentElement.scrollHeight;
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			const bottomOffset = 120; // Adjust this value if needed

			if (scrollTop + windowHeight >= documentHeight - bottomOffset) {
				return;
			}

			location.reload();
		}
	}
});

},{}]},{},[1])